import { BaseEntity } from 'processdelight-angular-components';

export class AbsenceType extends BaseEntity {
  type!: string;
  color?: string;
  hasLinkedAbsences?: boolean;

  constructor(obj: Partial<AbsenceType>) {
    super(obj);
    Object.assign(this, obj);
  }
}
