import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, of, switchMap, tap } from 'rxjs';
import { IshtarPermanenceService } from '../../services/project.service';
import {
  addContactDetailParam,
  addContactDetailParamResolved,
  getContactDetailParams,
  getContactDetailParamsResolved,
  removeContactDetailParam,
  removeContactDetailParamResolved,
  updateContactDetailParam,
  updateContactDetailParamResolved,
} from './contactDetailParam.actions';

@Injectable({ providedIn: 'root' })
export class ContactDetailParamEffects {
  constructor(
    private ishtarPermanenceService: IshtarPermanenceService,
    private actions$: Actions
  ) {}

  addContactDetailParam = createEffect(() =>
    this.actions$.pipe(
      ofType(addContactDetailParam),
      switchMap(({ contactDetailParam, callback, error }) =>
        this.ishtarPermanenceService
          .addContactDetailParam(contactDetailParam)
          .pipe(
            tap(() => (callback ? callback(contactDetailParam) : undefined)),
            switchMap((addedContactDetailParam) =>
              of(addContactDetailParamResolved({ addedContactDetailParam }))
            ),
            catchError((e) => {
              if (error) error(e);
              return [];
            })
          )
      )
    )
  );

  updateContactDetailParam = createEffect(() =>
    this.actions$.pipe(
      ofType(updateContactDetailParam),
      switchMap(({ contactDetailParam, callback, error }) =>
        this.ishtarPermanenceService
          .updateContactDetailParam(contactDetailParam)
          .pipe(
            tap(() => (callback ? callback(contactDetailParam) : undefined)),
            switchMap((updatedContactDetailParam) =>
              of(
                updateContactDetailParamResolved({ updatedContactDetailParam })
              )
            ),
            catchError((e) => {
              if (error) error(e);
              return [];
            })
          )
      )
    )
  );

  getContactDetailParams = createEffect(() =>
    this.actions$.pipe(
      ofType(getContactDetailParams),
      switchMap(({ callback }) =>
        this.ishtarPermanenceService.getContactDetailParams().pipe(
          switchMap((data) =>
            of(
              getContactDetailParamsResolved({
                result: data,
              })
            )
          ),
          tap(() => (callback ? callback() : undefined))
        )
      )
    )
  );

  removeContactDetailParam = createEffect(() =>
    this.actions$.pipe(
      ofType(removeContactDetailParam),
      switchMap(({ ishtarPermanenceContactDetailParamId, callback, error }) =>
        this.ishtarPermanenceService
          .removeContactDetailParam(ishtarPermanenceContactDetailParamId)
          .pipe(
            tap((x) => (callback ? callback(x) : undefined)),
            switchMap(() =>
              of(
                removeContactDetailParamResolved({
                  ishtarPermanenceContactDetailParamId,
                })
              )
            ),
            catchError((e) => {
              if (error) error(e);
              return [];
            })
          )
      )
    )
  );
}
