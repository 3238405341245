import { BaseEntity } from 'processdelight-angular-components';

export class ContactDetailParam extends BaseEntity {
  name?: string;
  isEditable?: boolean;

  constructor(obj: Partial<ContactDetailParam>) {
    super(obj);
    Object.assign(this, obj);
  }
}
