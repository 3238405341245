import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, of, switchMap, tap } from 'rxjs';
import { IshtarPermanenceService } from '../../services/project.service';
import {
  addAbsencePeriods,
  updateAbsencePeriods,
  removeAbsencePeriods,
  removeAbsencePeriodsResolved,
  updateAbsencePeriodsResolved,
  addAbsencePeriodsResolved,
} from './absence.actions';
import {
  addAbsences,
  getAbsences,
  removeAbsences,
  updateAbsences,
  addAbsencesResolved,
  getAbsencesResolved,
  removeAbsencesResolved,
  updateAbsencesResolved,
  getAbsencesForUserResolved,
  getAbsencesForUser,
  getAbsencePeriods,
  getAbsencePeriodsResolved,
} from './absence.actions';

@Injectable({ providedIn: 'root' })
export class AbsenceEffects {
  constructor(
    private ishtarPermanenceService: IshtarPermanenceService,
    private actions$: Actions
  ) {}

  getAbsences = createEffect(() =>
    this.actions$.pipe(
      ofType(getAbsences),
      switchMap(({ startDate, endDate, callback }) =>
        this.ishtarPermanenceService.getAbsences(startDate, endDate).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((result) =>
            of(
              getAbsencesResolved({
                result,
              })
            )
          )
        )
      )
    )
  );

  getAbsencesForUser = createEffect(() =>
    this.actions$.pipe(
      ofType(getAbsencesForUser),
      switchMap(({ callback }) =>
        this.ishtarPermanenceService.getAbsencesForUser().pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((result) =>
            of(
              getAbsencesForUserResolved({
                result,
              })
            )
          )
        )
      )
    )
  );

  updateAbsences = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAbsences),
      switchMap(({ absence, callback, error }) =>
        this.ishtarPermanenceService.updateAbsences(absence).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((updatedAbsence) =>
            of(
              updateAbsencesResolved({
                updatedAbsence,
              })
            )
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  addAbsences = createEffect(() =>
    this.actions$.pipe(
      ofType(addAbsences),
      switchMap(({ absence, callback, error }) =>
        this.ishtarPermanenceService.addAbsences(absence).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((addedAbsence) =>
            of(addAbsencesResolved({ addedAbsence }))
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  removeAbsences = createEffect(() =>
    this.actions$.pipe(
      ofType(removeAbsences),
      switchMap(({ ishtarPermanenceAbsenceId, callback, error }) =>
        this.ishtarPermanenceService
          .removeAbsences(ishtarPermanenceAbsenceId)
          .pipe(
            tap((x) => (callback ? callback(x) : undefined)),
            switchMap((ishtarPermanenceAbsenceIds) =>
              of(
                removeAbsencesResolved({
                  ishtarPermanenceAbsenceIds,
                })
              )
            ),
            catchError((e) => {
              if (error) error(e);
              return [];
            })
          )
      )
    )
  );

  getAbsencePeriods = createEffect(() =>
    this.actions$.pipe(
      ofType(getAbsencePeriods),
      switchMap(({ callback }) =>
        this.ishtarPermanenceService.getAbsencePeriods().pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((result) =>
            of(
              getAbsencePeriodsResolved({
                result,
              })
            )
          )
        )
      )
    )
  );

  addAbsencePeriods = createEffect(() =>
    this.actions$.pipe(
      ofType(addAbsencePeriods),
      switchMap(({ absencePeriods, callback, error }) =>
        this.ishtarPermanenceService.addAbsencePeriod(absencePeriods).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((addedAbsencePeriods) =>
            of(
              addAbsencePeriodsResolved({
                addedAbsencePeriods,
              })
            )
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  updateAbsencePeriods = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAbsencePeriods),
      switchMap(({ absencePeriods, callback, error }) =>
        this.ishtarPermanenceService.updateAbsencePeriod(absencePeriods).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((updatedAbsencePeriods) =>
            of(
              updateAbsencePeriodsResolved({
                updatedAbsencePeriods,
              })
            )
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  removeAbsencePeriods = createEffect(() =>
    this.actions$.pipe(
      ofType(removeAbsencePeriods),
      switchMap(({ ishtarPermanenceAbsencePeriodIds, callback, error }) =>
        this.ishtarPermanenceService
          .removeAbsencePeriod(ishtarPermanenceAbsencePeriodIds)
          .pipe(
            tap((x) => (callback ? callback(x) : undefined)),
            switchMap((ishtarPermanenceAbsencePeriodIds) =>
              of(
                removeAbsencePeriodsResolved({
                  ishtarPermanenceAbsencePeriodIds,
                })
              )
            ),
            catchError((e) => {
              if (error) error(e);
              return [];
            })
          )
      )
    )
  );
}
