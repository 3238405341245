import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map, Subject } from 'rxjs';
import { AppState } from 'src/app/shared/store/app.reducer';
import { ContactDetail } from '../../models/project/contactDetail';
import {
  getContactDetails,
  updateContactDetail,
  updatePhoneContactDetail,
  removeContactDetail,
  addContactDetail,
} from './contactDetail.actions';
import { contactDetailState } from './contactDetail.reducer';
import { BaseFacade } from '../base.facade';

@Injectable({
  providedIn: 'root',
})
export class ContactDetailFacade extends BaseFacade {
  contactDetails$ = this._store.pipe(
    select(contactDetailState),
    map((state) => state.contactDetails)
  );

  pagingCookie$ = this._store.pipe(
    select(contactDetailState),
    map((state) => state.pagingCookie)
  );

  totalRecordCount$ = this._store.pipe(
    select(contactDetailState),
    map((state) => state.totalRecordCount)
  );

  constructor(_store: Store<AppState>) {
    super(_store);
  }

  getContactDetails$(callback?: (contactDetails: ContactDetail[]) => void) {
    const subject = new Subject<ContactDetail[]>();
    this._store.dispatch(
      getContactDetails({
        callback: (x) => {
          subject.next(x);
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  addContactDetail(contactDetail: ContactDetail) {
    return this.dispatchAction$(addContactDetail, {
      contactDetail,
    });
  }

  updateContactDetail(contactDetail: ContactDetail) {
    return this.dispatchAction$(updateContactDetail, {
      contactDetail,
    });
  }

  updatePhone(contactDetail: ContactDetail) {
    this._store.dispatch(updatePhoneContactDetail({ contactDetail }));
  }

  removeContactDetail(ishtarPermanenceContactDetailIds: string[]) {
    return this.dispatchAction$(removeContactDetail, {
      ishtarPermanenceContactDetailIds,
    });
  }
}
