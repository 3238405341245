import { DateTime } from 'luxon';
import {
  BaseEntity,
  Frequency,
  GroupUser,
  Ranking,
} from 'processdelight-angular-components';
import { RecurrencyDay } from './recurrencyDay';
import { RecurrencyMonth } from './recurrencyMonth';
import { PermanenceUser } from './permanenceUser';
import { Department } from './department';

export class Recurrency extends BaseEntity {
  permanenceId!: string;
  frequencyId!: string;
  frequency!: Frequency;
  startDate?: DateTime;
  endDate?: DateTime;
  amount?: number;
  interval!: number;
  isDeleted?: boolean = false;
  departmentId?: string;
  department!: Department;
  recurrencyDays?: RecurrencyDay[];
  recurrencyMonths?: RecurrencyMonth[];
  user?: GroupUser;
  backups?: PermanenceUser[];
  rankingId?: string;
  ranking?: Ranking;
  fromDateTime?: DateTime;
  untilDateTime?: DateTime;

  constructor(obj: Partial<Recurrency>) {
    super(obj);
    Object.assign(this, obj);
    if (typeof obj.startDate == 'string')
      this.startDate = DateTime.fromISO(obj.startDate, { zone: 'utc' });
    if (typeof obj.endDate == 'string')
      this.endDate = DateTime.fromISO(obj.endDate, { zone: 'utc' });
    if (typeof obj.fromDateTime == 'string')
      this.fromDateTime = DateTime.fromISO(obj.fromDateTime, { zone: 'utc' });
    if (typeof obj.untilDateTime == 'string')
      this.untilDateTime = DateTime.fromISO(obj.untilDateTime, { zone: 'utc' });
    if (obj.fromDateTime instanceof DateTime)
      this.fromDateTime = DateTime.fromMillis(obj.fromDateTime.valueOf(), {
        zone: 'utc',
      });
    if (obj.untilDateTime instanceof DateTime)
      this.untilDateTime = DateTime.fromMillis(obj.untilDateTime.valueOf(), {
        zone: 'utc',
      });
    if (obj.startDate instanceof DateTime)
      this.startDate = DateTime.fromMillis(obj.startDate.valueOf(), {
        zone: 'utc',
      });
    if (obj.endDate instanceof DateTime)
      this.endDate = DateTime.fromMillis(obj.endDate.valueOf(), {
        zone: 'utc',
      });
    if (obj.user) this.user = new GroupUser(obj.user);
    if (obj.department) this.department = new Department(obj.department);
    if (obj.ranking) this.ranking = new Ranking(obj.ranking);
    if (obj.frequency) this.frequency = new Frequency(obj.frequency);
  }
}
