import { DateTime } from 'luxon';
import { PermanenceDuty } from './permanenceDuty';
import { BaseEntity, GroupUser } from 'processdelight-angular-components';

export class Exchange extends BaseEntity {
  title?: string;
  permanenceDutyId?: string;
  permanenceDuty!: PermanenceDuty;
  user?: GroupUser;
  startDate?: DateTime;
  endDate?: DateTime;
  approved!: boolean;

  constructor(obj: Partial<Exchange>) {
    super(obj);
    Object.assign(this, obj);
    if (typeof obj.startDate == 'string')
      this.startDate = DateTime.fromISO(obj.startDate, { zone: 'utc' });
    if (typeof obj.endDate == 'string')
      this.endDate = DateTime.fromISO(obj.endDate, { zone: 'utc' });
    if (obj.startDate instanceof DateTime)
      this.startDate = DateTime.fromMillis(obj.startDate.valueOf(), {
        zone: 'utc',
      });
    if (obj.endDate instanceof DateTime)
      this.endDate = DateTime.fromMillis(obj.endDate.valueOf(), {
        zone: 'utc',
      });
    if (obj.user) this.user = new GroupUser(obj.user);
    if (obj.permanenceDuty)
      this.permanenceDuty = new PermanenceDuty(obj.permanenceDuty);
  }
}
