import { createAction, props } from '@ngrx/store';
import { PermanenceTemplate } from '../../models/project/permanenceTemplate';
import { ActionBase } from '../action-base.interface';

export const getPermanenceTemplates = createAction(
  '[PermanenceTemplate] Get permanenceTemplates',
  props<{
    departmentId?: string;
    callback?: () => void;
  }>()
);

export const getPermanenceTemplateResolved = createAction(
  '[PermanenceTemplate] Get permanenceTemplates resolved',
  props<{
    result: PermanenceTemplate[];
  }>()
);

export const addPermanenceTemplate = createAction(
  '[PermanenceTemplate] Add permanenceTemplate',
  props<
    {
      permanenceTemplate: PermanenceTemplate;
    } & ActionBase<PermanenceTemplate[]>
  >()
);

export const addPermanenceTemplateResolved = createAction(
  '[PermanenceTemplate] Add permanenceTemplate resolved',
  props<{ addedPermanenceTemplate: PermanenceTemplate[] }>()
);

export const updatePermanenceTemplate = createAction(
  '[PermanenceTemplate] Update permanenceTemplate',
  props<
    {
      permanenceTemplate: PermanenceTemplate;
    } & ActionBase<PermanenceTemplate[]>
  >()
);

export const updatePermanenceTemplateResolved = createAction(
  '[PermanenceTemplate] Update permanenceTemplate resolved',
  props<{ updatedPermanenceTemplate: PermanenceTemplate[] }>()
);

export const removePermanenceTemplate = createAction(
  '[PermanenceTemplate] Remove permanenceTemplate',
  props<
    {
      ishtarPermanenceTemplateId: string;
    } & ActionBase<string[]>
  >()
);

export const removePermanenceTemplateResolved = createAction(
  '[PermanenceTemplate] Remove permanenceTemplate resolved',
  props<{
    ishtarPermanenceTemplateId: string;
  }>()
);

export const onDepartmentDeleteActionResolved = createAction(
  '[PermanenceTemplate] On department delete action resolved',
  props<{
    departmentId: string;
  }>()
);
