import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, of, switchMap, tap, mergeMap } from 'rxjs';
import { IshtarPermanenceService } from '../../services/project.service';
import {
  addRecurrence,
  addRecurrenceResolved,
  updateRecurrence,
  updateRecurrenceResolved,
  removeRecurrence,
  removeRecurrenceResolved,
  getRecurrencies,
  getRecurrenciesResolved,
} from './recurrence.actions';
import {
  getPermanenceDuties,
  getPermanenceDutiesResolved,
  removePermanenceDutyResolved,
  updateDutiesAfterRecurrenceAction,
} from '../permanenceDuty/permanenceDuty.actions';

@Injectable({ providedIn: 'root' })
export class RecurrenceEffects {
  constructor(
    private ishtarPermanenceService: IshtarPermanenceService,
    private actions$: Actions
  ) {}

  addRecurrence = createEffect(() =>
    this.actions$.pipe(
      ofType(addRecurrence),
      switchMap(
        ({ recurrence, calendarStartDate, calendarEndDate, callback, error }) =>
          this.ishtarPermanenceService.addRecurrency(recurrence).pipe(
            switchMap((addedRecurrence) => [
              addRecurrenceResolved({ addedRecurrence }),
              getPermanenceDuties({
                startOfMonth: calendarStartDate,
                endOfMonth: calendarEndDate,
                departments: undefined,
                sync: false,
                callback: () =>
                  callback ? callback(addedRecurrence) : undefined,
              }),
            ]),
            catchError((e) => {
              if (error) error(e);
              return [];
            })
          )
      )
    )
  );

  getReccurencies = createEffect(() =>
    this.actions$.pipe(
      ofType(getRecurrencies),
      mergeMap(({ callback }) =>
        this.ishtarPermanenceService.getRecurrencies().pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((data) =>
            of(
              getRecurrenciesResolved({
                result: data,
              })
            )
          )
        )
      )
    )
  );

  removeRecurrency = createEffect(() =>
    this.actions$.pipe(
      ofType(removeRecurrence),
      mergeMap(({ ishtarPermanenceRecurrencyId, callback, error }) =>
        this.ishtarPermanenceService
          .removeRecurrence(ishtarPermanenceRecurrencyId)
          .pipe(
            tap((x) => (callback ? callback(x) : undefined)),
            switchMap(({ deletedPermanenceIds, deletedRecurrencyId }) => [
              removeRecurrenceResolved({
                deletedRecurrencyId,
              }),
              removePermanenceDutyResolved({
                ishtarPermanenceIds: deletedPermanenceIds,
              }),
              updateDutiesAfterRecurrenceAction({ deletedRecurrencyId }),
            ]),
            catchError((e) => {
              if (error) error(e);
              return [];
            })
          )
      )
    )
  );

  updateRecurrence = createEffect(() =>
    this.actions$.pipe(
      ofType(updateRecurrence),
      switchMap(({ recurrence, startDate, endDate, callback, error }) =>
        this.ishtarPermanenceService
          .updateRecurrence(recurrence, startDate, endDate)
          .pipe(
            // tap((x) => (callback ? callback(x) : undefined)),
            switchMap(({ updatedRecurrency, recurrencyDuties }) => [
              updateRecurrenceResolved({ updatedRecurrency }),
              updateDutiesAfterRecurrenceAction({
                deletedRecurrencyId: recurrence.id,
              }),
              getPermanenceDuties({
                startOfMonth: startDate,
                endOfMonth: endDate,
                departments: undefined,
                sync: false,
                callback: () =>
                  callback
                    ? callback({ updatedRecurrency, recurrencyDuties })
                    : undefined,
              }),
              getRecurrencies({}),
              // getPermanenceDutiesResolved({ result: recurrencyDuties }),
              // updateDutiesAfterRecurrenceAction({
              //   deletedRecurrencyId: recurrence.ishtarPermanenceRecurrencyId,
              // }),
            ]),
            catchError((e) => {
              if (error) error(e);
              return [];
            })
          )
      )
    )
  );
}
