import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { enableProdMode, importProvidersFrom, inject } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { EffectsModule, provideEffects } from '@ngrx/effects';
import { provideStore, StoreModule } from '@ngrx/store';
import {
  provideStoreDevtools,
  StoreDevtoolsModule,
} from '@ngrx/store-devtools';
import { AppComponent, appRoutes } from './app/app.component';
import { environment } from './environments/environment';
import {
  StartUpService,
  varlicense$,
} from './app/core//services/startup.service';
import {
  APP_STARTUP,
  ISHTAR365_APP_IDENTIFIER,
  ISHTAR365_CLIENTID,
  ISHTAR365_FUNCTIONS_SCOPE,
  ISHTAR365_FUNCTIONS_URL,
  ISHTAR365_INITIAL_ROUTE,
  ISHTAR365_INSTANCE,
  ISHTAR365_LAST_ROUTE_KEY,
  ISHTAR365_URL,
  MsalModule,
  SessionExpiredModule,
  microsoftAuthenticationInterceptor,
} from 'processdelight-angular-components';
import { MAT_LUXON_DATE_ADAPTER_OPTIONS } from '@angular/material-luxon-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import jsonData from './assets/country-codes.json';
import { MatSnackBarModule } from '@angular/material/snack-bar';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    { provide: ISHTAR365_CLIENTID, useValue: environment.clientId },
    { provide: ISHTAR365_INSTANCE, useValue: environment.instance },
    { provide: ISHTAR365_URL, useValue: environment.ishtar365 },
    { provide: ISHTAR365_APP_IDENTIFIER, useFactory: () => location.origin },
    { provide: ISHTAR365_LAST_ROUTE_KEY, useValue: environment.lastRouteKey },
    { provide: ISHTAR365_INITIAL_ROUTE, useValue: ['overview'] },
    { provide: ISHTAR365_FUNCTIONS_URL, useValue: environment.ishtarFunctions },
    {
      provide: ISHTAR365_FUNCTIONS_SCOPE,
      useValue: environment.ishtarFunctionsScope,
    },
    {
      provide: APP_STARTUP,
      useFactory: () => {
        const service = inject(StartUpService);
        return () => service.boot();
      },
    },
    {
      provide: MAT_LUXON_DATE_ADAPTER_OPTIONS,
      useValue: { useUtc: true, firstDayOfWeek: 1 },
    },
    {
      provide: MAT_DATE_LOCALE,
      useFactory: () => {
        const languages = navigator.languages.filter((l) => l.includes('-'));
        let countryCode = 'GB';
        if (languages.length === 0 && navigator.language !== 'en') {
          const countries = jsonData;
          countryCode = countries.filter((c) =>
            c.languages.includes(navigator.language)
          )[0].cca2;
        } else if (languages.length) {
          countryCode = languages[0].split('-')[1];
        }
        return (
          (varlicense$.value?.language ?? 'en') +
          '-' +
          countryCode.toUpperCase()
        );
      },
    },
    provideRouter(appRoutes),
    provideStore({}),
    provideEffects([]),
    provideStoreDevtools({ logOnly: false }),
    provideHttpClient(
      withInterceptors([microsoftAuthenticationInterceptor('')])
    ),
    importProvidersFrom(
      StoreModule.forRoot({}),
      EffectsModule.forRoot([]),
      StoreDevtoolsModule.instrument({ logOnly: environment.production }),
      MsalModule,
      MatSnackBarModule,
      SessionExpiredModule
    ),
    provideAnimations(),
  ],
});
