import { BaseEntity } from 'processdelight-angular-components';
import { ContactDetail } from './contactDetail';
import { ContactDetailParam } from './contactDetailParam';

export class ContactDetailParamValue extends BaseEntity {
  contactDetailId?: string;
  contactDetail?: ContactDetail;
  paramId?: string;
  param!: ContactDetailParam;
  paramValue!: string;

  constructor(obj: Partial<ContactDetailParamValue>) {
    super(obj);
    Object.assign(this, obj);
    if (obj.contactDetail)
      this.contactDetail = new ContactDetail(obj.contactDetail);
    if (obj.param) this.param = new ContactDetailParam(obj.param);
  }
}
