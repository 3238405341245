import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as fromCoreFeature from './core/store/core.feature';

export const MY_FORMATS = {
  display: {
    dateInput: 'dd/MM/yyyy',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    StoreModule.forFeature(
      fromCoreFeature.featureKey,
      fromCoreFeature.reducers
    ),
    EffectsModule.forFeature(fromCoreFeature.effects),
  ],
  exports: [CommonModule, RouterModule, MatIconModule],
})
export class CoreModule {}
