import { Action, createReducer, on } from '@ngrx/store';
import { AppState } from 'src/app/shared/store/app.reducer';
import {
  addPermanenceTemplateResolved,
  removePermanenceTemplateResolved,
  getPermanenceTemplateResolved,
  updatePermanenceTemplateResolved,
  onDepartmentDeleteActionResolved,
} from './permanenceTemplate.actions';
import { PermanenceTemplate } from '../../models/project/permanenceTemplate';

export const featureSlice = 'permanenceTemplates';

export interface State {
  permanenceTemplates?: PermanenceTemplate[];
  permanenceTemplate?: PermanenceTemplate;
  ishtarPermanenceTemplateId?: string;
}

const defaultState: State = {
  permanenceTemplates: undefined,
  permanenceTemplate: undefined,
  ishtarPermanenceTemplateId: undefined,
};

export function Reducer(state: State | undefined, action: Action) {
  return permanenceTemplateReducer(state, action);
}

export const initialState: State = defaultState;
export const permanenceTemplateReducer = createReducer(
  initialState,
  on(addPermanenceTemplateResolved, (state, { addedPermanenceTemplate }) => ({
    ...state,
    permanenceTemplates: [...state.permanenceTemplates!].concat([
      ...addedPermanenceTemplate,
    ]),
  })),
  on(getPermanenceTemplateResolved, (state, { result }) => ({
    ...state,
    permanenceTemplates: state.permanenceTemplates
      ? [...state.permanenceTemplates!]
          .filter(
            (permanenceTemplate) =>
              !result?.map((r) => r.id).includes(permanenceTemplate.id)
          )
          .concat([...result])
      : [...result],
  })),
  on(
    updatePermanenceTemplateResolved,
    (state, { updatedPermanenceTemplate }) => ({
      ...state,
      permanenceTemplates: state.permanenceTemplates?.map(
        (p) => updatedPermanenceTemplate.find((u) => p.id === u.id) ?? p
      ),
    })
  ),
  on(
    removePermanenceTemplateResolved,
    (state, { ishtarPermanenceTemplateId }) => ({
      ...state,
      permanenceTemplates: state.permanenceTemplates?.filter(
        (c) => c.id != ishtarPermanenceTemplateId
      ),
    })
  ),
  on(onDepartmentDeleteActionResolved, (state, { departmentId }) => ({
    ...state,
    permanenceTemplates: state.permanenceTemplates?.filter(
      (c) => c.department.id != departmentId
    ),
  }))
);
export const permanenceTemplateState = (state: AppState) =>
  state.coreFeature.permanenceTemplates;
