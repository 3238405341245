<processdelight-top-layout
  [sideBarActions]="sideBarActions"
  [iconActions]="iconActions"
  [navColor]="(navColor$ | async) || '#124464'"
  [navContrast]="(navContrast$ | async) || '#fff'"
  [actionBarTitleHidden]="true"
  [sideBarButtonEnabled]="true"
  [appLogo]="appLogo"
  [appName]="title"
>
  <router-outlet></router-outlet
></processdelight-top-layout>
<!-- 
[orgLogo]="orgLogo$ | async"
[userName]="userName$ | async" -->
