import { createAction, props } from '@ngrx/store';
import { ContactDetail } from '../../models/project/contactDetail';
import { ActionBase } from '../action-base.interface';

export const getContactDetails = createAction(
  '[ContactDetail] Get contactDetails',
  props<{
    callback?: (contactDetails: ContactDetail[]) => void;
  }>()
);

export const getContactDetailsResolved = createAction(
  '[ContactDetail] Get contactDetails resolved',
  props<{
    result: ContactDetail[];
  }>()
);

export const addContactDetail = createAction(
  '[ContactDetail] Add contactDetail',
  props<
    {
      contactDetail: ContactDetail;
    } & ActionBase<ContactDetail[]>
  >()
);

export const addContactDetailResolved = createAction(
  '[ContactDetail] Add contactDetail resolved',
  props<{ addedContactDetail: ContactDetail }>()
);

export const updateContactDetail = createAction(
  '[ContactDetail] Update contactDetail',
  props<
    {
      contactDetail: ContactDetail;
    } & ActionBase<ContactDetail>
  >()
);

export const updateContactDetailResolved = createAction(
  '[ContactDetail] Update contactDetail resolved',
  props<{ contactDetail: ContactDetail }>()
);

export const updatePhoneContactDetail = createAction(
  '[ContactDetail] Update mobile phone of contactDetail',
  props<{ contactDetail: ContactDetail }>()
);

export const updatePhoneContactDetailResolved = createAction(
  '[ContactDetail] Update mobile phone of contactDetail',
  props<{ contactDetail: ContactDetail }>()
);

export const removeContactDetail = createAction(
  '[ContactDetail] Remove contactDetail',
  props<
    {
      ishtarPermanenceContactDetailIds: string[];
    } & ActionBase<string[]>
  >()
);
export const removeContactDetailResolved = createAction(
  '[ContactDetail] Remove contactDetail resolved',
  props<{ ids: string[] }>()
);
