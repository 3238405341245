import { DateTime } from 'luxon';
import { Department } from './department';
import { BaseEntity, Day } from 'processdelight-angular-components';

export class PermanenceTemplate extends BaseEntity {
  title?: string;
  fromDayId?: string;
  fromDay!: Day;
  untilDayId?: string;
  untilDay!: Day;
  fromTime?: DateTime;
  untilTime?: DateTime;
  departmentId?: string;
  department!: Department;

  constructor(obj: Partial<PermanenceTemplate>, doMapping = true) {
    super(obj);
    Object.assign(this, obj);
    if (obj.department) this.department = new Department(obj.department);
    if (typeof obj.fromTime == 'string')
      this.fromTime = DateTime.fromISO(obj.fromTime, { zone: 'utc' });
    if (typeof obj.untilTime == 'string')
      this.untilTime = DateTime.fromISO(obj.untilTime, { zone: 'utc' });
    if (obj.fromTime instanceof DateTime)
      this.fromTime = DateTime.fromMillis(obj.fromTime.valueOf(), {
        zone: 'utc',
      });
    if (obj.untilTime instanceof DateTime)
      this.untilTime = DateTime.fromMillis(obj.untilTime.valueOf(), {
        zone: 'utc',
      });
    if (doMapping) {
      const fromDay = DateTime.fromFormat(this.fromDay.name!, 'EEEE', {
        zone: 'utc',
      });
      const untilDay = DateTime.fromFormat(this.untilDay.name!, 'EEEE', {
        zone: 'utc',
      });
      this.fromTime = fromDay.set({
        hour: this.fromTime!.hour,
        minute: this.fromTime!.minute,
      });
      this.untilTime = untilDay.set({
        hour: this.untilTime!.hour,
        minute: this.untilTime!.minute,
      });
    }

    // new Date(
    //   Date.UTC(
    //     fromDay.getUTCFullYear(),
    //     fromDay.getUTCMonth(),
    //     fromDay.getUTCDate(),
    //     this.fromTime!.getUTCHours(),
    //     this.fromTime?.getUTCMinutes()
    //   )
    // );

    // this.untilTime = new Date(
    //   Date.UTC(
    //     untilDay.getUTCFullYear(),
    //     untilDay.getUTCMonth(),
    //     untilDay.getUTCDate(),
    //     this.untilTime!.getUTCHours(),
    //     this.untilTime?.getUTCMinutes()
    //   )
    // );
  }
}
