import { createAction, props } from '@ngrx/store';
import { PermanenceDuty } from '../../models/project/permanenceDuty';
import { Department } from '../../models/project/department';
import { Exchange } from '../../models/project/exchange';
import { ActionBase } from '../action-base.interface';
import { DateTime } from 'luxon';

export const getPermanenceDuties = createAction(
  '[PermanenceDuty] Get permanenceDuties',
  props<
    {
      startOfMonth: DateTime;
      endOfMonth: DateTime;
      departments: Department[] | undefined;
      sync: boolean;
    } & ActionBase<PermanenceDuty[]>
  >()
);

export const getExchanges = createAction(
  '[PermanenceDuty] Get exchanges for user',
  props<ActionBase<Exchange[]>>()
);

export const getExchangesResolved = createAction(
  '[PermanenceDuty] Get exchanges for user resolved',
  props<{
    exchanges: Exchange[];
  }>()
);

export const removeRecurrencyOccurence = createAction(
  '[PermanenceDuty] remove occurrence of recurrency',
  props<{
    recurrencyId: string;
    recurrencyOccurence: number;
  }>()
);

export const updateDutiesAfterRecurrenceAction = createAction(
  '[PermanenceDuty] update duties after recurrence action',
  props<{
    deletedRecurrencyId: string;
  }>()
);
export const updateDutiesAfterDepartmentDelete = createAction(
  '[PermanenceDuty] update duties after department deleted',
  props<{
    departmentId: string;
  }>()
);

export const addExchange = createAction(
  '[PermanenceDuty] add exchange',
  props<
    {
      exchange: Exchange;
      permanence: PermanenceDuty;
      isRecurrence: boolean;
    } & ActionBase<{
      addedExchange: Exchange;
      exchangeDuties: PermanenceDuty[];
      permanence: PermanenceDuty;
    }>
  >()
);

export const addExchangeResolved = createAction(
  '[PermanenceDuty] add exchange resolved',
  props<{
    addedExchange: Exchange;
    exchangeDuties: PermanenceDuty[];
    permanence: PermanenceDuty;
  }>()
);

export const exchangeAccepted = createAction(
  '[PermanenceDuty] exchange accepted',
  props<
    {
      exchangeToUpdateId: string;
      originalDuty: PermanenceDuty;
      exchangeDuty: PermanenceDuty;
      splittedDuties: PermanenceDuty[];
    } & ActionBase<{
      createdDuties: PermanenceDuty[];
      createdExchangeDuty: PermanenceDuty;
      updatedOriginalDuty: PermanenceDuty;
      deletedOriginalDuty: string;
      updatedExchange: Exchange;
      oldExChangeId: string;
    }>
  >()
);
export const exchangeAcceptedResolved = createAction(
  '[PermanenceDuty] exchange accepted resolved',
  props<{
    updatedExchange: Exchange;
    deletedOriginalDuty: string;
    createdDuties: PermanenceDuty[];
    createdExchangeDuty: PermanenceDuty;
    updatedOriginalDuty: PermanenceDuty;
    oldExchangeId: string;
  }>()
);

export const removeExchange = createAction(
  '[PermanenceDuty] remove exchange',
  props<
    {
      exchange: Exchange;
    } & ActionBase<{
      deletedExchangeId: string;
      originalPermanence: PermanenceDuty;
    }>
  >()
);

export const removeExchangeResolved = createAction(
  '[PermanenceDuty] remove exchange resolved',
  props<{
    deletedExchangeId: string;
    originalPermanence: PermanenceDuty;
  }>()
);

export const getPermanenceDutiesForUser = createAction(
  '[PermanenceDuty] Get permanenceDuties for user',
  props<
    {
      startDate: DateTime;
      endDate: DateTime;
      userId: string;
    } & ActionBase<boolean>
  >()
);

export const getPermanenceDutieForUserResolved = createAction(
  '[PermanenceDuty] Get permanenceDuties for user resolved',
  props<{
    permanenceDuties: PermanenceDuty[];
    lastDuty: boolean;
  }>()
);

export const getDutiesForSyncResolved = createAction(
  '[PermanenceDuty] Get duties for sync',
  props<{
    permanenceDuties: PermanenceDuty[];
  }>()
);

export const getPermanenceDutiesResolved = createAction(
  '[PermanenceDuty] Get permanenceDuties resolved',
  props<{
    result: PermanenceDuty[];
  }>()
);

// export const checkBlindspotsCreation = createAction(
//   '[PermanenceDuty] Get blindspots that were created',
//   props<{
//     departmentId: string;
//     templates: PermanenceTemplate[];
//     newItemStartDate?: Date;
//     newItemEndDate?: Date;
//     itemId?: string;
//     callback?: (result: {
//       blindspotsCreated: PermanenceDuty[];
//       blindspotsDeleted: string[];
//     }) => void;
//   }>()
// );

export const addPermanenceDuty = createAction(
  '[PermanenceDuty] Add permanenceDuty',
  props<
    {
      permanenceDuty: PermanenceDuty;
      userIds: string[];
      loggedInUserId: string;
    } & ActionBase<PermanenceDuty[]>
  >()
);

export const addPermanenceDutyResolved = createAction(
  '[PermanenceDuty] Add permanenceDuty resolved',
  props<{ addedDuties: PermanenceDuty[]; loggedInUserId: string }>()
);

export const updatePermanenceDuty = createAction(
  '[PermanenceDuty] Update permanenceDuty',
  props<
    {
      permanenceDuty: PermanenceDuty;
      userIds: string[];
    } & ActionBase<PermanenceDuty>
  >()
);

export const updateDutyComment = createAction(
  '[PermanenceDuty] Update duty comment',
  props<
    {
      permanenceDuty: PermanenceDuty;
      loggedInUserId: string;
    } & ActionBase<PermanenceDuty>
  >()
);

export const updateDutyCommentResolved = createAction(
  '[PermanenceDuty] Update duty comment resolved',
  props<{
    updatedPermanenceDuty: PermanenceDuty;
  }>()
);

export const updatePermanenceDutyResolved = createAction(
  '[PermanenceDuty] Update permanenceDuty resolved',
  props<{ updatedPermanenceDuty: PermanenceDuty }>()
);

export const removePermanenceDuty = createAction(
  '[PermanenceDuty] Remove permanenceDuty',
  props<
    {
      ishtarPermanenceId: string;
    } & ActionBase<string[]>
  >()
);

export const removePermanenceDutyResolved = createAction(
  '[PermanenceDuty] Remove permanenceDuty resolved',
  props<{
    ishtarPermanenceIds: string[];
  }>()
);
