import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, of, switchMap, tap } from 'rxjs';
import { IshtarPermanenceService } from '../../services/project.service';
import {
  addContactDetailParamValue,
  addContactDetailParamValueResolved,
  getContactDetailParamValue,
  getContactDetailParamValueResolved,
  getContactDetailParamValues,
  getContactDetailParamValuesResolved,
  updateContactDetailParamValue,
  updateContactDetailParamValueResolved,
  removeContactDetailParamValues,
  removeContactDetailParamValuesResolved,
} from './contactDetailParamValue.actions';

@Injectable({ providedIn: 'root' })
export class ContactDetailParamValueEffects {
  constructor(
    private ishtarPermanenceService: IshtarPermanenceService,
    private actions$: Actions
  ) {}

  getContactDetailParamValues = createEffect(() =>
    this.actions$.pipe(
      ofType(getContactDetailParamValues),
      switchMap(({ callback }) =>
        this.ishtarPermanenceService.getContactDetailParamValues().pipe(
          switchMap((data) =>
            of(
              getContactDetailParamValuesResolved({
                result: data,
              })
            )
          ),
          tap(() => (callback ? callback() : undefined))
        )
      )
    )
  );

  // getContactDetailParamValue = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(getContactDetailParamValue),
  //     switchMap(({ ishtarPermanenceContactDetailParamValueId, callback }) =>
  //       this.ishtarPermanenceService
  //         .getContactDetailParamValue(ishtarPermanenceContactDetailParamValueId)
  //         .pipe(
  //           tap((contactDetailParamValue) =>
  //             callback ? callback(contactDetailParamValue) : undefined
  //           ),
  //           switchMap((contactDetailParamValue) =>
  //             of(
  //               getContactDetailParamValueResolved({ contactDetailParamValue })
  //             )
  //           ),
  //           catchError((e) => [])
  //         )
  //     )
  //   )
  // );

  updateContactDetailParamValue = createEffect(() =>
    this.actions$.pipe(
      ofType(updateContactDetailParamValue),
      mergeMap(({ contactDetailParamValue, callback, error }) =>
        this.ishtarPermanenceService
          .updateContactDetailParamValue(contactDetailParamValue)
          .pipe(
            tap((contactDetailParamValue) =>
              callback ? callback(contactDetailParamValue) : undefined
            ),
            switchMap((contactDetailParamValue) =>
              of(
                updateContactDetailParamValueResolved({
                  contactDetailParamValue: contactDetailParamValue,
                })
              )
            ),
            catchError((e) => {
              if (error) error(e);
              return [];
            })
          )
      )
    )
  );

  addContactDetailParamValue = createEffect(() =>
    this.actions$.pipe(
      ofType(addContactDetailParamValue),
      mergeMap(({ contactDetailParamValue, callback, error }) =>
        this.ishtarPermanenceService
          .addContactDetailParamValue(contactDetailParamValue)
          .pipe(
            tap((contactDetailParamValue) =>
              callback ? callback(contactDetailParamValue) : undefined
            ),
            switchMap((addedContactDetailParamValue) =>
              of(
                addContactDetailParamValueResolved({
                  addedContactDetailParamValue,
                })
              )
            ),
            catchError((e) => {
              if (error) error(e);
              return [];
            })
          )
      )
    )
  );

  removeContactDetailParamValues = createEffect(() =>
    this.actions$.pipe(
      ofType(removeContactDetailParamValues),
      mergeMap(
        ({ ishtarPermanenceContactDetailParamValueIds, callback, error }) =>
          this.ishtarPermanenceService
            .removeContactDetailParamValues(
              ishtarPermanenceContactDetailParamValueIds
            )
            .pipe(
              tap((x) => (callback ? callback(x) : undefined)),
              switchMap((deletedParamValueIds) =>
                of(
                  removeContactDetailParamValuesResolved({
                    deletedParamValueIds,
                  })
                )
              ),
              catchError((e) => {
                if (error) error(e);
                return [];
              })
            )
      )
    )
  );
}
