import {
  Country,
  GroupUser,
  BaseEntity,
} from 'processdelight-angular-components';
import { ContactDetailDepartment } from './contactDetailDepartment';

export class ContactDetail extends BaseEntity {
  title?: string;
  userId?: string;
  user?: GroupUser;
  departments?: ContactDetailDepartment[];
  jobTitle?: string;
  telephoneNumber?: string;
  telehponeCountryId?: string;
  telephoneCountry?: Country;
  mobileNumber?: string;
  mobileCountryId?: string;
  mobileCountry?: Country;

  constructor(obj: Partial<ContactDetail>) {
    super(obj);
    Object.assign(this, obj);
    if (obj.departments)
      this.departments = obj.departments.map(
        (x: ContactDetailDepartment) => new ContactDetailDepartment(x)
      );
    if (obj.user) this.user = new GroupUser(obj.user);
  }
}
