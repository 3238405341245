import { createAction, props } from '@ngrx/store';
import { AbsenceType } from '../../models/project/absenceType';
import { ActionBase } from '../action-base.interface';

export const getAbsenceTypes = createAction(
  '[AbsenceType] Get absenceTypes',
  props<{
    callback?: (absenceTypes: AbsenceType[]) => void;
  }>()
);

export const getAbsenceTypesResolved = createAction(
  '[AbsenceType] Get absenceTypes resolved',
  props<{
    result: AbsenceType[];
  }>()
);
export const updateAbsenceType = createAction(
  '[AbsenceType] Update absenceType',
  props<
    {
      absenceTypes: AbsenceType[];
    } & ActionBase<AbsenceType[]>
  >()
);

export const updateAbsenceTypeResolved = createAction(
  '[AbsenceType] Update absenceType resolved',
  props<{ absenceTypes: AbsenceType[] }>()
);

export const removeAbsenceType = createAction(
  '[AbsenceType] Remove absenceType',
  props<
    {
      ishtarPermanenceAbsenceTypeIds: string[];
    } & ActionBase<string[]>
  >()
);

export const removeAbsenceTypeResolved = createAction(
  '[AbsenceType] Remove absenceType resolved',
  props<{ ishtarPermanenceAbsenceTypeIds: string[] }>()
);

export const addAbsenceType = createAction(
  '[AbsenceType] Add absenceType',
  props<
    {
      absenceTypes: AbsenceType[];
    } & ActionBase<AbsenceType[]>
  >()
);

export const addAbsenceTypeResolved = createAction(
  '[AbsenceType] Add absenceType resolved',
  props<{ addedAbsenceTypes: AbsenceType[] }>()
);
