import { Action, createReducer, on } from '@ngrx/store';
import { AppState } from 'src/app/shared/store/app.reducer';
import { ContactDetail } from '../../models/project/contactDetail';
import {
  getContactDetailsResolved,
  updateContactDetailResolved,
  updatePhoneContactDetailResolved,
  removeContactDetailResolved,
  addContactDetailResolved,
} from './contactDetail.actions';

export const featureSlice = 'contactDetail';

export interface State {
  contactDetails?: ContactDetail[];
  pagingCookie: string;
  totalRecordCount: number;
}

const defaultState: State = {
  contactDetails: undefined,
  pagingCookie: '',
  totalRecordCount: 0,
};

export function Reducer(state: State | undefined, action: Action) {
  return contactDetailReducer(state, action);
}

export const initialState: State = defaultState;
export const contactDetailReducer = createReducer(
  initialState,
  on(getContactDetailsResolved, (state, { result }) => ({
    ...state,
    contactDetails: [...result],
  })),
  on(addContactDetailResolved, (state, { addedContactDetail }) => ({
    ...state,
    contactDetails: [addedContactDetail].concat([...state.contactDetails!]),
  })),
  on(updateContactDetailResolved, (state, { contactDetail }) => ({
    ...state,
    contactDetails: state.contactDetails?.map((p) =>
      p.id == contactDetail.id ? contactDetail : p
    ),
  })),
  on(updatePhoneContactDetailResolved, (state, { contactDetail }) => ({
    ...state,
    contactDetails: state.contactDetails?.map((p) =>
      p.id == contactDetail.id ? contactDetail : p
    ),
  })),
  on(removeContactDetailResolved, (state, { ids }) => ({
    ...state,
    contactDetails: state.contactDetails?.filter((p) => !ids.includes(p.id!)),
  }))
);

export const contactDetailState = (state: AppState) =>
  state.coreFeature.contactDetail;
