import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { IshtarPermanenceService } from '../../services/project.service';
import {
  getContactDetails,
  getContactDetailsResolved,
  updateContactDetail,
  updateContactDetailResolved,
  updatePhoneContactDetail,
  updatePhoneContactDetailResolved,
  removeContactDetail,
  removeContactDetailResolved,
  addContactDetail,
  addContactDetailResolved,
} from './contactDetail.actions';
import { varcountries$ } from '../../services/startup.service';
import * as phoneUtilInstance from 'google-libphonenumber';
import { ContactDetail } from '../../models/project/contactDetail';
const phoneUtil = phoneUtilInstance.PhoneNumberUtil.getInstance();

@Injectable({ providedIn: 'root' })
export class ContactDetailEffects {
  constructor(
    private ishtarPermanenceService: IshtarPermanenceService,
    private actions$: Actions
  ) {}

  getContactDetails = createEffect(() =>
    this.actions$.pipe(
      ofType(getContactDetails),
      switchMap(({ callback }) =>
        this.ishtarPermanenceService.getContactDetails().pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          withLatestFrom(varcountries$),
          switchMap(([data, countries]) => {
            const d = data.map((c) => {
              if (c.mobileNumber == undefined) {
                const browserLanguageCode = navigator.language.slice(-2);
                const mobileNumber = phoneUtil.parseAndKeepRawInput(
                  c.user?.user?.mobilePhone,
                  browserLanguageCode
                );
                return new ContactDetail({
                  ...c,
                  mobileNumber: mobileNumber.getNationalNumber(),
                  mobileCountry:
                    countries?.find(
                      (c) => c.telephoneCode === mobileNumber.getCountryCode()
                    ) ?? undefined,
                });
              }
              return c;
            });
            return of(getContactDetailsResolved({ result: d }));
          })
          // tap((x) => (callback ? callback() : undefined))
        )
      )
    )
  );

  updateContactDetail = createEffect(() =>
    this.actions$.pipe(
      ofType(updateContactDetail),
      switchMap(({ contactDetail, callback, error }) =>
        this.ishtarPermanenceService.updateContactDetail(contactDetail).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((contactDetail) =>
            of(updateContactDetailResolved({ contactDetail }))
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  addContactDetail = createEffect(() =>
    this.actions$.pipe(
      ofType(addContactDetail),
      switchMap(({ contactDetail, callback, error }) =>
        this.ishtarPermanenceService.addContactDetail(contactDetail).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((addedContactDetail) =>
            of(
              addContactDetailResolved({
                addedContactDetail: addedContactDetail[0],
              })
            )
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );
  updatePhoneContactDetail = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePhoneContactDetail),
      switchMap(({ contactDetail }) =>
        of(updatePhoneContactDetailResolved({ contactDetail }))
      )
    )
  );

  removeContactDetail = createEffect(() =>
    this.actions$.pipe(
      ofType(removeContactDetail),
      switchMap(({ ishtarPermanenceContactDetailIds, callback, error }) =>
        this.ishtarPermanenceService
          .removeContactDetail(ishtarPermanenceContactDetailIds)
          .pipe(
            tap((x) => (callback ? callback(x) : undefined)),
            switchMap((ids) =>
              of(
                removeContactDetailResolved({
                  ids,
                })
              )
            ),
            catchError((e) => {
              if (error) error(e);
              return [];
            })
          )
      )
    )
  );

  capitalizeKeys(obj: any, ...ignoredProperties: string[]): any {
    const ignoredPropertiesLower = ignoredProperties.map((p) =>
      p.toLowerCase()
    );

    if (Array.isArray(obj))
      return [...obj.map((o) => this.capitalizeKeys(o, ...ignoredProperties))];
    else if (obj instanceof Object)
      return Object.entries(obj).reduce(
        (acc, e) => ({
          ...acc,

          [e[0].charAt(0).toUpperCase() + e[0].slice(1)]:
            ignoredPropertiesLower.includes(e[0].toLowerCase())
              ? e[1]
              : this.capitalizeKeys(e[1], ...ignoredProperties),
        }),

        {}
      );
    else return obj;
  }
}
