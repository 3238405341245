import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, of, switchMap, tap } from 'rxjs';
import { IshtarPermanenceService } from '../../services/project.service';
import {
  addPermanenceTemplate,
  addPermanenceTemplateResolved,
  updatePermanenceTemplate,
  updatePermanenceTemplateResolved,
  removePermanenceTemplate,
  removePermanenceTemplateResolved,
  getPermanenceTemplates,
  getPermanenceTemplateResolved,
} from './permanenceTemplate.actions';

@Injectable({ providedIn: 'root' })
export class PermanenceTemplateEffects {
  constructor(
    private ishtarPermanenceService: IshtarPermanenceService,
    private actions$: Actions
  ) {}

  addPermanenceTemplate = createEffect(() =>
    this.actions$.pipe(
      ofType(addPermanenceTemplate),
      switchMap(({ permanenceTemplate, callback, error }) =>
        this.ishtarPermanenceService
          .addPermanenceTemplate(permanenceTemplate)
          .pipe(
            tap((x) => (callback ? callback(x) : undefined)),
            switchMap((addedPermanenceTemplate) =>
              of(addPermanenceTemplateResolved({ addedPermanenceTemplate }))
            ),
            catchError((e) => {
              if (error) error(e);
              return [];
            })
          )
      )
    )
  );

  getPermanenceTemplates = createEffect(() =>
    this.actions$.pipe(
      ofType(getPermanenceTemplates),
      switchMap(({ departmentId, callback }) =>
        this.ishtarPermanenceService.getPermanenceTemplates(departmentId).pipe(
          switchMap((data) =>
            of(
              getPermanenceTemplateResolved({
                result: data,
              })
            )
          ),
          tap(() => (callback ? callback() : undefined))
        )
      )
    )
  );

  removePermanenceTemplate = createEffect(() =>
    this.actions$.pipe(
      ofType(removePermanenceTemplate),
      switchMap(({ ishtarPermanenceTemplateId, callback, error }) =>
        this.ishtarPermanenceService
          .removePermanenceTemplate(ishtarPermanenceTemplateId)
          .pipe(
            tap((x) => (callback ? callback(x) : undefined)),
            switchMap(() =>
              of(
                removePermanenceTemplateResolved({
                  ishtarPermanenceTemplateId,
                })
              )
            ),
            catchError((e) => {
              if (error) error(e);
              return [];
            })
          )
      )
    )
  );

  updatePermanenceTemplate = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePermanenceTemplate),
      switchMap(({ permanenceTemplate, callback, error }) =>
        this.ishtarPermanenceService
          .updatePermanenceTemplate(permanenceTemplate)
          .pipe(
            tap((x) => (callback ? callback(x) : undefined)),
            switchMap((updatedPermanenceTemplate) =>
              of(
                updatePermanenceTemplateResolved({ updatedPermanenceTemplate })
              )
            ),
            catchError((e) => {
              if (error) error(e);
              return [];
            })
          )
      )
    )
  );
}
