import { Action, createReducer, on } from '@ngrx/store';
import { AppState } from 'src/app/shared/store/app.reducer';
import {
  addRecurrenceResolved,
  getRecurrenciesResolved,
  updateRecurrenceResolved,
  removeRecurrenceResolved,
} from './recurrence.actions';
import { Recurrency } from '../../models/project/recurrency';

export const featureSlice = 'recurrencies';

export interface State {
  recurrencies?: Recurrency[];
  recurrency?: Recurrency;
  ishtarPermanenceRecurrencyId?: string;
}

const defaultState: State = {
  recurrencies: undefined,
  recurrency: undefined,
  ishtarPermanenceRecurrencyId: undefined,
};

export function Reducer(state: State | undefined, action: Action) {
  return recurrencyReducer(state, action);
}

export const initialState: State = defaultState;
export const recurrencyReducer = createReducer(
  initialState,
  on(addRecurrenceResolved, (state, { addedRecurrence }) => ({
    ...state,
    recurrencies: [...state.recurrencies!].concat([addedRecurrence]),
  })),
  on(getRecurrenciesResolved, (state, { result }) => ({
    ...state,
    recurrencies: state.recurrencies
      ? [...state.recurrencies!]
          .filter(
            (recurrency) => !result?.map((r) => r.id).includes(recurrency.id)
          )
          .concat([...result])
      : [...result],
  })),
  on(updateRecurrenceResolved, (state, { updatedRecurrency }) => ({
    ...state,
    recurrencies: state.recurrencies?.map((p) =>
      p.id == updatedRecurrency.id ? updatedRecurrency : p
    ),
  })),
  on(removeRecurrenceResolved, (state, { deletedRecurrencyId }) => ({
    ...state,
    recurrencies: state.recurrencies?.filter(
      (c) => c.id !== deletedRecurrencyId
    ),
  }))
);
export const recurrenceState = (state: AppState) =>
  state.coreFeature.recurrencies;
