import { DateTime } from 'luxon';
import { GroupUser } from 'processdelight-angular-components';
import { PermanenceUser } from './permanenceUser';
import { BaseEntity } from 'processdelight-angular-components';
import { Department } from './department';
import { Recurrency } from './recurrency';

export class PermanenceDuty extends BaseEntity {
  title?: string;
  fromDateTime?: DateTime;
  untilDateTime?: DateTime;
  user!: GroupUser;
  isInExchange?: boolean;
  isDeleted?: boolean = false;
  recurrencyId?: string;
  recurrency?: Recurrency;
  recurrencyOccurence?: number;
  backups?: PermanenceUser[];
  departmentId?: string;
  department?: Department;
  comment?: string;

  constructor(obj: Partial<PermanenceDuty>) {
    super(obj);
    Object.assign(this, obj);
    if (typeof obj.fromDateTime == 'string')
      this.fromDateTime = DateTime.fromISO(obj.fromDateTime, { zone: 'utc' });
    if (typeof obj.untilDateTime == 'string')
      this.untilDateTime = DateTime.fromISO(obj.untilDateTime, { zone: 'utc' });
    if (obj.fromDateTime instanceof DateTime)
      this.fromDateTime = DateTime.fromMillis(obj.fromDateTime.valueOf(), {
        zone: 'utc',
      });
    if (obj.untilDateTime instanceof DateTime)
      this.untilDateTime = DateTime.fromMillis(obj.untilDateTime.valueOf(), {
        zone: 'utc',
      });
    if (obj.user) this.user = new GroupUser(obj.user);
    if (obj.department) this.department = new Department(obj.department);
    if (obj.recurrency) this.recurrency = new Recurrency(obj.recurrency);
  }
}
