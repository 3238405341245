import { BaseEntity, GroupUser } from 'processdelight-angular-components';
import { AbsenceType } from './absenceType';
import { DateTime } from 'luxon';

export class Absence extends BaseEntity {
  title?: string;
  user!: GroupUser;
  startDate?: DateTime;
  endDate?: DateTime;
  absenceTypeId?: string;
  absenceType?: AbsenceType;

  constructor(obj: Partial<Absence>) {
    super(obj);
    Object.assign(this, obj);
    if (typeof obj.startDate == 'string')
      this.startDate = DateTime.fromISO(obj.startDate, { zone: 'utc' });
    if (typeof obj.endDate == 'string')
      this.endDate = DateTime.fromISO(obj.endDate, { zone: 'utc' });
    if (obj.startDate instanceof DateTime)
      this.startDate = DateTime.fromMillis(obj.startDate.valueOf(), {
        zone: 'utc',
      });
    if (obj.endDate instanceof DateTime)
      this.endDate = DateTime.fromMillis(obj.endDate.valueOf(), {
        zone: 'utc',
      });
    if (obj.user) this.user = new GroupUser(obj.user);
    if (obj.absenceType) this.absenceType = new AbsenceType(obj.absenceType);
  }
}
