import { createAction, props } from '@ngrx/store';
import { Department } from '../../models/project/department';
import { ActionBase } from '../action-base.interface';

export const getDepartments = createAction(
  '[Department] Get departments',
  props<{
    callback?: () => void;
  }>()
);

export const getDepartmentsResolved = createAction(
  '[Department] Get departments resolved',
  props<{
    result: Department[];
  }>()
);
export const updateDepartment = createAction(
  '[Department] Update department',
  props<
    {
      departments: Department[];
    } & ActionBase<Department[]>
  >()
);

export const updateDepartmentResolved = createAction(
  '[Department] Update department resolved',
  props<{ departments: Department[] }>()
);

export const removeDepartment = createAction(
  '[Department] Remove department',
  props<
    {
      ishtarPermanenceDepartmentIds: string[];
    } & ActionBase<string[]>
  >()
);

export const removeDepartmentResolved = createAction(
  '[Department] Remove department resolved',
  props<{ ishtarPermanenceDepartmentIds: string[] }>()
);

export const addDepartment = createAction(
  '[Department] Add department',
  props<
    {
      departments: Department[];
    } & ActionBase<Department[]>
  >()
);

export const addDepartmentResolved = createAction(
  '[Department] Add department resolved',
  props<{ addedDepartments: Department[] }>()
);
