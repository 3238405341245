import { Action, createReducer, on } from '@ngrx/store';
import { AppState } from 'src/app/shared/store/app.reducer';
import {
  addPermanenceDutyResolved,
  getPermanenceDutiesResolved,
  updatePermanenceDutyResolved,
  removePermanenceDutyResolved,
  removeRecurrencyOccurence,
  updateDutiesAfterRecurrenceAction,
  addExchangeResolved,
  getExchangesResolved,
  removeExchangeResolved,
  getPermanenceDutieForUserResolved,
  exchangeAcceptedResolved,
  updateDutyCommentResolved,
  updateDutiesAfterDepartmentDelete,
  getDutiesForSyncResolved,
} from './permanenceDuty.actions';
import { PermanenceDuty } from '../../models/project/permanenceDuty';
import { getTodayInUTC } from 'processdelight-angular-components';
import { Exchange } from '../../models/project/exchange';

export const featureSlice = 'permanenceDuties';

export interface State {
  exchanges?: Exchange[];
  myDuties?: PermanenceDuty[];
  permanenceDuties?: PermanenceDuty[];
  permanenceDuty?: PermanenceDuty;
  ishtarPermanenceDutyId?: string;
}

const defaultState: State = {
  exchanges: undefined,
  permanenceDuties: undefined,
  myDuties: [],
  permanenceDuty: undefined,
  ishtarPermanenceDutyId: undefined,
};

export function Reducer(state: State | undefined, action: Action) {
  return permanenceDutyReducer(state, action);
}

export const initialState: State = defaultState;
export const permanenceDutyReducer = createReducer(
  initialState,
  on(addPermanenceDutyResolved, (state, { addedDuties, loggedInUserId }) => ({
    ...state,
    permanenceDuties: [...state.permanenceDuties!].concat([...addedDuties]),
    myDuties: state.myDuties
      ? [...state.myDuties].concat([...addedDuties])
      : [...addedDuties],
  })),
  on(getPermanenceDutiesResolved, (state, { result }) => ({
    ...state,
    permanenceDuties: state.permanenceDuties
      ? [...state.permanenceDuties!]
          .filter((permanenceDuties) =>
            permanenceDuties.id == null
              ? !result.some(
                  (r) =>
                    r.recurrencyOccurence ==
                      permanenceDuties.recurrencyOccurence &&
                    r.recurrency?.id == permanenceDuties.recurrency?.id
                )
              : !result?.map((r) => r.id).includes(permanenceDuties.id)
          )
          .concat([...result])
      : [...result],
  })),
  on(getDutiesForSyncResolved, (state, { permanenceDuties }) => ({
    ...state,
    permanenceDuties: [...permanenceDuties],
  })),
  on(
    getPermanenceDutieForUserResolved,
    (state, { permanenceDuties, lastDuty }) => ({
      ...state,
      myDuties: state.myDuties
        ? [...state.myDuties]
            .filter((p) =>
              p.id == null
                ? !permanenceDuties.some(
                    (r) =>
                      r.recurrencyOccurence == p.recurrencyOccurence &&
                      r.recurrency?.id == p.recurrency?.id
                  )
                : !permanenceDuties?.map((r) => r.id).includes(p.id)
            )
            .concat([...permanenceDuties])
        : [...permanenceDuties],
    })
  ),
  on(updatePermanenceDutyResolved, (state, { updatedPermanenceDuty }) => ({
    ...state,
    permanenceDuties: state.permanenceDuties?.map((p) =>
      p.id == updatedPermanenceDuty.id ? updatedPermanenceDuty : p
    ),
    myDuties: state.myDuties?.map((p) =>
      p.id == updatedPermanenceDuty.id ? updatedPermanenceDuty : p
    ),
  })),
  on(updateDutyCommentResolved, (state, { updatedPermanenceDuty }) => ({
    ...state,
    permanenceDuties: state.permanenceDuties?.map((p) =>
      p.id == updatedPermanenceDuty.id ? updatedPermanenceDuty : p
    ),
    myDuties: state.myDuties?.map((p) =>
      p.id == updatedPermanenceDuty.id ? updatedPermanenceDuty : p
    ),
  })),
  on(removePermanenceDutyResolved, (state, { ishtarPermanenceIds }) => ({
    ...state,
    permanenceDuties: state.permanenceDuties?.filter(
      (c) => !ishtarPermanenceIds.includes(c.id)
    ),
    myDuties: state.myDuties?.filter(
      (c) => !ishtarPermanenceIds.includes(c.id)
    ),
  })),
  on(
    removeRecurrencyOccurence,
    (state, { recurrencyId, recurrencyOccurence }) => ({
      ...state,
      permanenceDuties: state.permanenceDuties?.filter(
        (c) =>
          !(
            c.recurrency?.id == recurrencyId &&
            recurrencyOccurence == c.recurrencyOccurence &&
            c.id == null
          )
      ),
      myDuties: state.myDuties?.filter(
        (c) =>
          !(
            c.recurrency?.id == recurrencyId &&
            recurrencyOccurence == c.recurrencyOccurence &&
            c.id == null
          )
      ),
    })
  ),
  on(updateDutiesAfterRecurrenceAction, (state, { deletedRecurrencyId }) => ({
    ...state,
    permanenceDuties: state.permanenceDuties?.filter(
      (p) =>
        !(
          p.recurrency?.id == deletedRecurrencyId &&
          p.fromDateTime! > getTodayInUTC()
        )
    ),
    myDuties: state.myDuties?.filter(
      (p) =>
        !(
          p.recurrency?.id == deletedRecurrencyId &&
          p.fromDateTime! > getTodayInUTC()
        )
    ),
  })),
  on(updateDutiesAfterDepartmentDelete, (state, { departmentId }) => ({
    ...state,
    permanenceDuties: state.permanenceDuties?.filter(
      (p) => p.department!.id != departmentId
    ),
    myDuties: state.myDuties?.filter((p) => p.department!.id != departmentId),
  })),
  on(getExchangesResolved, (state, { exchanges }) => ({
    ...state,
    exchanges: [...exchanges],
    permanenceDuties: state.permanenceDuties?.filter(
      (d) =>
        exchanges.find(
          (e) =>
            e.permanenceDutyId == d.id &&
            e.approved == true &&
            e.permanenceDuty.isDeleted == true
        ) == undefined
    ),
  })),
  on(
    addExchangeResolved,
    (state, { addedExchange, exchangeDuties, permanence }) => ({
      ...state,
      exchanges: state.exchanges?.concat(addedExchange),
      permanenceDuties: [...state.permanenceDuties!]
        .filter((p) => p.id != addedExchange.permanenceDuty.id)
        .concat([...exchangeDuties]),
      myDuties: [...state.myDuties!]
        .filter((p) => p.id != addedExchange.permanenceDuty.id)
        .concat([addedExchange.permanenceDuty]),
    })
  ),
  on(
    removeExchangeResolved,
    (state, { deletedExchangeId, originalPermanence }) => ({
      ...state,
      exchanges: state.exchanges?.filter((e) => e.id != deletedExchangeId),
      permanenceDuties: state.permanenceDuties
        ?.filter(
          (p) => p.id != originalPermanence.id && p.id != deletedExchangeId
        )
        .concat({
          ...originalPermanence,
        }),
      myDuties: state.myDuties
        ?.filter(
          (p) => p.id != originalPermanence.id && p.id != deletedExchangeId
        )
        .concat({
          ...originalPermanence,
        }),
    })
  ),
  on(
    exchangeAcceptedResolved,
    (
      state,
      {
        updatedExchange,
        deletedOriginalDuty,
        createdDuties,
        createdExchangeDuty,
        oldExchangeId,
      }
    ) => ({
      ...state,
      exchanges: state.exchanges
        ?.filter((e) => e.id != oldExchangeId)
        .concat(updatedExchange),
      myDuties: state.myDuties
        ?.filter((p) => p.id != deletedOriginalDuty)
        .concat(createdExchangeDuty),
      permanenceDuties: state.permanenceDuties
        ?.filter((p) => p.id != oldExchangeId && p.id != deletedOriginalDuty)
        .concat([...createdDuties, createdExchangeDuty]),
    })
  )
);
export const permanenceDutyState = (state: AppState) =>
  state.coreFeature.permanenceDuties;
