import { createAction, props } from '@ngrx/store';
import { ContactDetailParamValue } from '../../models/project/contactDetailParamValue';
import { ActionBase } from '../action-base.interface';

export const getContactDetailParamValue = createAction(
  '[ContactDetailParamValue] Get contactDetailParamValue',
  props<{
    ishtarPermanenceContactDetailParamValueId: string;
    callback?: (contactDetailParamValue: ContactDetailParamValue) => void;
  }>()
);

export const getContactDetailParamValueResolved = createAction(
  '[ContactDetailParamValue] Get contactDetailParamValue resolved',
  props<{ contactDetailParamValue: ContactDetailParamValue }>()
);

export const getContactDetailParamValues = createAction(
  '[ContactDetailParamValues] Get contactDetailParamValues',
  props<{
    callback?: () => void;
  }>()
);

export const getContactDetailParamValuesResolved = createAction(
  '[ContactDetailParamValues] Get contactDetailParamValues resolved',
  props<{ result: ContactDetailParamValue[] }>()
);

export const updateContactDetailParamValue = createAction(
  '[ContactDetailParamValue] Update contactDetailParamValue',
  props<
    {
      contactDetailParamValue: ContactDetailParamValue;
    } & ActionBase<ContactDetailParamValue>
  >()
);

export const updateContactDetailParamValueResolved = createAction(
  '[ContactDetailParamValue] Update contactDetailParamValue resolved',
  props<{ contactDetailParamValue: ContactDetailParamValue }>()
);

export const addContactDetailParamValue = createAction(
  '[ContactDetailParamValue] Add contactDetailParamValue',
  props<
    {
      contactDetailParamValue: ContactDetailParamValue;
    } & ActionBase<ContactDetailParamValue>
  >()
);

export const addContactDetailParamValueResolved = createAction(
  '[ContactDetailParamValue] Add contactDetailParamValue resolved',
  props<{ addedContactDetailParamValue: ContactDetailParamValue }>()
);

export const removeContactDetailParamValues = createAction(
  '[ContactDetailParamValue] Remove contactDetailParamValues',
  props<
    {
      ishtarPermanenceContactDetailParamValueIds: string[];
    } & ActionBase<string[]>
  >()
);
export const removeContactDetailParamValuesResolved = createAction(
  '[ContactDetailParamValue] Remove contactDetailParamValues resolved',
  props<{ deletedParamValueIds: string[] }>()
);
