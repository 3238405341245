import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, of, switchMap, tap } from 'rxjs';
import { IshtarPermanenceService } from '../../services/project.service';
import {
  addAbsenceType,
  addAbsenceTypeResolved,
  getAbsenceTypes,
  getAbsenceTypesResolved,
  removeAbsenceType,
  removeAbsenceTypeResolved,
  updateAbsenceType,
  updateAbsenceTypeResolved,
} from './absence-type.actions';

@Injectable({ providedIn: 'root' })
export class AbsenceTypeEffects {
  constructor(
    private ishtarPermanenceService: IshtarPermanenceService,
    private actions$: Actions
  ) {}

  getAbsenceTypes = createEffect(() =>
    this.actions$.pipe(
      ofType(getAbsenceTypes),
      switchMap(({ callback }) =>
        this.ishtarPermanenceService.getAbsenceTypes().pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((result) =>
            of(
              getAbsenceTypesResolved({
                result,
              })
            )
          )
        )
      )
    )
  );

  updateAbsenceType = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAbsenceType),
      switchMap(({ absenceTypes, callback, error }) =>
        this.ishtarPermanenceService.updateAbsenceType(absenceTypes).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((absenceTypes) =>
            of(
              updateAbsenceTypeResolved({
                absenceTypes,
              })
            )
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  addAbsenceType = createEffect(() =>
    this.actions$.pipe(
      ofType(addAbsenceType),
      switchMap(({ absenceTypes, callback, error }) =>
        this.ishtarPermanenceService.addAbsenceType(absenceTypes).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((addedAbsenceTypes) =>
            of(addAbsenceTypeResolved({ addedAbsenceTypes }))
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  removeAbsenceType = createEffect(() =>
    this.actions$.pipe(
      ofType(removeAbsenceType),
      switchMap(({ ishtarPermanenceAbsenceTypeIds, callback, error }) =>
        this.ishtarPermanenceService
          .removeAbsenceType(ishtarPermanenceAbsenceTypeIds)
          .pipe(
            tap((x) => (callback ? callback(x) : undefined)),
            switchMap(() =>
              of(
                removeAbsenceTypeResolved({
                  ishtarPermanenceAbsenceTypeIds,
                })
              )
            ),
            catchError((e) => {
              if (error) error(e);
              return [];
            })
          )
      )
    )
  );
}
