import { Action, createReducer, on } from '@ngrx/store';
import { AppState } from 'src/app/shared/store/app.reducer';
import { ContactDetailParam } from '../../models/project/contactDetailParam';
import {
  addContactDetailParamResolved,
  getContactDetailParamsResolved,
  removeContactDetailParamResolved,
  updateContactDetailParamResolved,
} from './contactDetailParam.actions';

export const featureSlice = 'contactDetailParam';

export interface State {
  contactDetailParams?: ContactDetailParam[];
  contactDetailParam?: ContactDetailParam;
  ishtarPermanenceContactDetailParamId?: string;
}

const defaultState: State = {
  contactDetailParams: undefined,
  contactDetailParam: undefined,
  ishtarPermanenceContactDetailParamId: undefined,
};

export function Reducer(state: State | undefined, action: Action) {
  return contactDetailParamReducer(state, action);
}

export const initialState: State = defaultState;
export const contactDetailParamReducer = createReducer(
  initialState,
  on(addContactDetailParamResolved, (state, { addedContactDetailParam }) => ({
    ...state,
    contactDetailParams: [addedContactDetailParam].concat([
      ...state.contactDetailParams!,
    ]),
  })),
  on(
    updateContactDetailParamResolved,
    (state, { updatedContactDetailParam }) => ({
      ...state,
      contactDetailParams: state.contactDetailParams?.map((p) =>
        p.id == updatedContactDetailParam.id ? updatedContactDetailParam : p
      ),
    })
  ),
  on(getContactDetailParamsResolved, (state, { result }) => ({
    ...state,
    contactDetailParams: [...result],
  })),
  on(
    removeContactDetailParamResolved,
    (state, { ishtarPermanenceContactDetailParamId }) => ({
      ...state,
      contactDetailParams: state.contactDetailParams?.filter(
        (c) => c.id != ishtarPermanenceContactDetailParamId
      ),
    })
  )
);
export const contactDetailParamState = (state: AppState) =>
  state.coreFeature.contactDetailParam;
