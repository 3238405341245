import { Action, createReducer, on } from '@ngrx/store';
import { AppState } from 'src/app/shared/store/app.reducer';
import { ContactDetailParamValue } from '../../models/project/contactDetailParamValue';
import {
  addContactDetailParamValueResolved,
  getContactDetailParamValueResolved,
  getContactDetailParamValuesResolved,
  updateContactDetailParamValueResolved,
  removeContactDetailParamValuesResolved,
} from './contactDetailParamValue.actions';

export const featureSlice = 'contactDetailParamValue';

export interface State {
  contactDetailParamValues?: ContactDetailParamValue[];
  contactDetailParamValue?: ContactDetailParamValue;
  ishtarPermanenceContactDetailParamValueId?: string;
}

const defaultState: State = {
  contactDetailParamValues: undefined,
  contactDetailParamValue: undefined,
  ishtarPermanenceContactDetailParamValueId: undefined,
};

export function Reducer(state: State | undefined, action: Action) {
  return contactDetailParamValueReducer(state, action);
}

export const initialState: State = defaultState;
export const contactDetailParamValueReducer = createReducer(
  initialState,
  on(getContactDetailParamValuesResolved, (state, { result }) => ({
    ...state,
    contactDetailParamValues: [...result],
  })),
  on(
    getContactDetailParamValueResolved,
    (state, { contactDetailParamValue }) => ({
      ...state,
      contactDetailParamValue: { ...contactDetailParamValue },
    })
  ),
  on(
    updateContactDetailParamValueResolved,
    (state, { contactDetailParamValue }) => ({
      ...state,
      contactDetailParamValues: state.contactDetailParamValues?.map((p) =>
        p.id == contactDetailParamValue.id ? contactDetailParamValue : p
      ),
    })
  ),
  on(
    addContactDetailParamValueResolved,
    (state, { addedContactDetailParamValue }) => ({
      ...state,
      contactDetailParamValues: [addedContactDetailParamValue].concat([
        ...state.contactDetailParamValues!,
      ]),
    })
  ),
  on(
    removeContactDetailParamValuesResolved,
    (state, { deletedParamValueIds }) => ({
      ...state,
      contactDetailParamValues: state.contactDetailParamValues?.filter(
        (p) => !deletedParamValueIds.includes(p.id!)
      ),
    })
  )
);

export const contactDetailParamValueState = (state: AppState) =>
  state.coreFeature.contactDetailParamValue;
