import { GroupUser } from 'processdelight-angular-components';
import { BaseEntity } from 'processdelight-angular-components';
import { DepartmentUser } from './departmentUser';

export class Department extends BaseEntity {
  name!: string;
  supervisorId?: string;
  supervisor?: GroupUser;
  phoneNumber?: string;
  users?: DepartmentUser[] = [];
  isDeleted?: boolean = false;

  constructor(obj: Partial<Department>) {
    super(obj);
    Object.assign(this, obj);
  }
}
