import { BaseEntity } from 'processdelight-angular-components';
import { Department } from './department';
import { ContactDetail } from './contactDetail';

export class ContactDetailDepartment extends BaseEntity {
  departmentId?: string;
  contactDetailId?: string;
  department?: Department;
  contactDetail?: ContactDetail;

  constructor(obj: Partial<ContactDetailDepartment>) {
    super(obj);
    Object.assign(this, obj);
    if (obj.department) {
      this.department = new Department(obj.department);
    }
    if (obj.contactDetail) {
      this.contactDetail = new ContactDetail(obj.contactDetail);
    }
  }
}
