import { createAction, props } from '@ngrx/store';
import { Recurrency } from '../../models/project/recurrency';
import { ActionBase } from '../action-base.interface';
import { PermanenceDuty } from '../../models/project/permanenceDuty';
import { DateTime } from 'luxon';

export const getRecurrencies = createAction(
  '[Recurrence] Get recurrencies',
  props<{
    callback?: (recurrencies: Recurrency[]) => void;
  }>()
);

export const getRecurrenciesResolved = createAction(
  '[Recurrence] Get recurrencies resolved',
  props<{
    result: Recurrency[];
  }>()
);

export const addRecurrence = createAction(
  '[Recurrence] Add recurrence',
  props<
    {
      recurrence: Recurrency;
      calendarStartDate: DateTime;
      calendarEndDate: DateTime;
    } & ActionBase<Recurrency>
  >()
);

export const addRecurrenceResolved = createAction(
  '[Recurrence] Add recurrence resolved',
  props<{ addedRecurrence: Recurrency }>()
);

export const updateRecurrence = createAction(
  '[Recurrence] Update recurrence',
  props<
    {
      recurrence: Recurrency;
      startDate: DateTime;
      endDate: DateTime;
    } & ActionBase<{
      updatedRecurrency: Recurrency;
      recurrencyDuties: PermanenceDuty[];
    }>
  >()
);

export const updateRecurrenceResolved = createAction(
  '[Recurrence] Update recurrence resolved',
  props<{ updatedRecurrency: Recurrency }>()
);

export const removeRecurrence = createAction(
  '[Recurrence] Remove recurrence',
  props<
    {
      ishtarPermanenceRecurrencyId: string;
    } & ActionBase<{
      deletedPermanenceIds: string[];
      deletedRecurrencyId: string;
    }>
  >()
);

export const removeRecurrenceResolved = createAction(
  '[Recurrence] Remove recurrence resolved',
  props<{
    deletedRecurrencyId: string;
  }>()
);
