import { createAction, props } from '@ngrx/store';
import { ContactDetailParam } from '../../models/project/contactDetailParam';
import { ActionBase } from '../action-base.interface';

export const addContactDetailParam = createAction(
  '[ContactDetailParam] Add ContactDetailParam',
  props<
    {
      contactDetailParam: ContactDetailParam;
    } & ActionBase<ContactDetailParam>
  >()
);

export const addContactDetailParamResolved = createAction(
  '[ContactDetailParam] Add ContactDetailParam resolved',
  props<{ addedContactDetailParam: ContactDetailParam }>()
);

export const updateContactDetailParam = createAction(
  '[ContactDetailParam] update ContactDetailParam',
  props<
    {
      contactDetailParam: ContactDetailParam;
    } & ActionBase<ContactDetailParam>
  >()
);

export const updateContactDetailParamResolved = createAction(
  '[ContactDetailParam] update ContactDetailParam resolved',
  props<{ updatedContactDetailParam: ContactDetailParam }>()
);

export const getContactDetailParams = createAction(
  '[ContactDetailParam] Get contactDetailParams',
  props<{
    callback?: () => void;
  }>()
);

export const getContactDetailParamsResolved = createAction(
  '[ContactDetailParam] Get contactDetailParams resolved',
  props<{
    result: ContactDetailParam[];
  }>()
);

export const removeContactDetailParam = createAction(
  '[ContactDetailParam] Remove contactDetailParam',
  props<
    {
      ishtarPermanenceContactDetailParamId: string;
    } & ActionBase<string>
  >()
);

export const removeContactDetailParamResolved = createAction(
  '[ContactDetailParam] Remove contactDetailParam resolved',
  props<{
    ishtarPermanenceContactDetailParamId: string;
  }>()
);
