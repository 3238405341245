import { DateTime } from 'luxon';
import { BaseEntity } from 'processdelight-angular-components';

export class AbsencePeriod extends BaseEntity {
  title!: string;
  startDate?: DateTime;
  endDate?: DateTime;
  limit?: number;

  constructor(obj: Partial<AbsencePeriod>) {
    super(obj);
    Object.assign(this, obj);
    if (typeof obj.startDate == 'string')
      this.startDate = DateTime.fromISO(obj.startDate, { zone: 'utc' });
    if (typeof obj.endDate == 'string')
      this.endDate = DateTime.fromISO(obj.endDate, { zone: 'utc' });
  }
}
